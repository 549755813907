import React from 'react';
import { graphql } from 'gatsby';
import { Link } from 'gatsby';
import get from 'lodash/get';
import DatePicker from 'react-datepicker';
import Helmet from 'react-helmet';

import Footer from '../components/footer';
import Layout from '../components/layout';
import VirtualTour from '../components/virtual-tour.js';
import NavSection from '../components/nav-section';

class ToursPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      virtualTour: 'active',
      guidedTours: '',
      tourDate: '',
      tourTime: '',
      quantity: 0,
    };
  }

  incrementQuantity = e => {
    e.preventDefault();
    this.setState({ quantity: parseInt(this.state.quantity) + 1 });
  };

  handleCounter = e => {
    let targetValue = e.target.value;
    let reg = new RegExp('^[0-9]*$');

    if (reg.test(targetValue) && targetValue > 0) {
      this.setState({ quantity: targetValue });
    } else {
      this.setState({ quantity: 0 });
    }
  };

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.sectionScrollHandler);
    }
  }

  sectionScrollHandler = e => {
    let winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    let virtualTourPosition =
      document.getElementById('virtualTour') &&
      document.getElementById('virtualTour').offsetTop;
    let guidedToursPosition =
      document.getElementById('guidedTours') &&
      document.getElementById('guidedTours').offsetTop;

    if (winScroll >= virtualTourPosition) {
      document
        .querySelectorAll('.side-scrollers ,.center-btns')
        .forEach(function (el) {
          el.classList.remove('active');
        });
      document.getElementById('li-01') &&
        document.getElementById('li-01').classList.add('active');
      document.getElementById('btn-01') &&
        document.getElementById('btn-01').classList.add('active');
    }
    if (winScroll >= guidedToursPosition) {
      document
        .querySelectorAll('.side-scrollers ,.center-btns')
        .forEach(function (el) {
          el.classList.remove('active');
        });
      document.getElementById('li-02') &&
        document.getElementById('li-02').classList.add('active');
      document.getElementById('btn-02') &&
        document.getElementById('btn-02').classList.add('active');
    }
  };

  scrollHandler = e => {
    this.setState({
      virtualTour: '',
      guidedTours: '',
    });
    this.setState({ [e.target.dataset.id]: 'active' });
    var target = document.getElementById(e.target.dataset.id);
    target.scrollIntoView(true);
  };

  render() {
    const navMenus = get(this, 'props.data.allContentfulNavigation.edges');
    const {
      virtualTour,
      guidedTours,
      tourDate,
      tourTime,
      quantity,
    } = this.state;

    const {
      sectionTitle,
      sectionBodyIntro,
      admissionContent,
      guidedTourContent,
      sectionBodyOutro,
      guidedTourSectionImage,
      admissionContentTitle,
      guidedTourContentTitle,
    } = get(this, 'props.data.allContentfulToursPageGuidedTourSection.edges')[0].node;
    

    const {
      pageTitle,
      section1Tab,
      section2Tab,
      section3Tab,
      section1Title,
      section2,
      nameInputPlaceholder,
      companyInputPlaceholder,
      contactInputPlaceholder,
      emailInputPlaceholder,
      bookingTypeInputPlaceholder,
      datePickerInputPlaceholder,
      timePickerInputPlaceholder,
      ticketTypeInputPlaceholder,
      quantityInputPlaceholder,
      withGuidedTourInputPlaceholder,
      tourTypeInputPlaceholder,
      messageInputPlaceholder,
      newsletterInputPlaceholder,
      submitInputPlaceholder
    } = get(this, 'props.data.allContentfulTourTourPage.nodes[0]');
    
    const footer = get(this, 'props.data.allContentfulFooter.edges')
    
    return (
      <Layout>
        <NavSection navMenus={navMenus} navTheme="nav-white" />
        <Helmet title="Tours" />

        <div className="tour-header">
          <h1>{pageTitle}</h1>
        </div>
        <section id="link-tabs" className="pt-4 pb-4">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <ul className="nav justify-content-center">
                  <li className="font-gtaM">
                    <input
                      data-id="virtualTour"
                      id="btn-01"
                      className={`center-btns nav-link quick-link ${virtualTour}`}
                      onClick={this.scrollHandler}
                      value={section1Tab}
                      type="button"
                    />
                  </li>
                  <li className="font-gtaM">
                    <input
                      data-id="guidedTours"
                      id="btn-02"
                      className={`center-btns nav-link quick-link ${guidedTours}`}
                      onClick={this.scrollHandler}
                      value={section2Tab}
                      type="button"
                    />
                  </li>
                  <li className="font-gtaM">
                    <Link to="/venues/museum">
                      <input
                        className="center-btns nav-link quick-link"
                        value={section3Tab}
                        type="button"
                      />
                    </Link>
                  </li>

                  <div className="sidenav">
                    <li className={virtualTour}>
                      <input
                        data-id="virtualTour"
                        className={`font-gtaM ${virtualTour} side-scrollers`}
                        id="li-01"
                        onClick={this.scrollHandler}
                        value="01/"
                        type="button"
                      />
                    </li>
                    <li className={guidedTours}>
                      <input
                        data-id="guidedTours"
                        className={`font-gtaM ${guidedTours} side-scrollers`}
                        id="li-02"
                        onClick={this.scrollHandler}
                        value="02/"
                        type="button"
                      />
                    </li>
                    <li>
                      <Link to="/venues/museum">
                        <input
                          className="font-gtaM side-scrollers"
                          id="li-03"
                          value="03/"
                          type="button"
                        />
                      </Link>
                    </li>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="virtual-tour-container pt-5 ">
          <div className="container sm-w85">
            <div className="row">
              <div className="col-sm-6 mt-3">
                <h3 className="brown">{section1Tab}</h3>
                <h1 className="red">{section1Title}</h1>
              </div>
            </div>
          </div>
          {/* <VirtualTour url="https://palacio-virtual-tour.netlify.com/tour" /> */}
          <VirtualTour url="https://exsight360.com/virtual-tours/palacio-de-memoria/v8/tour.html" />

        </section>

        <section id="guidedTours" className="mb60 pb-3 ">
          <div className="container sm-w85 bordered-top pt-5">
            <div className="row pb-4">
              <div className="col-md-6 mt-2">
                <h3 className="brown">{section2Tab}</h3>
                <h1 className="red">{sectionTitle}</h1>
                <p>{section2.sectionBodyIntro.sectionBodyIntro}
                </p>
                <h4 className="font-gtaM mt30">{admissionContentTitle}</h4>
                <p
                  dangerouslySetInnerHTML={{
                    __html: section2.admissionContent.admissionContent
                  }}
                >  
                </p>
                <h4 className="font-gtaM mt30">{guidedTourContentTitle}</h4>
                <p
                  dangerouslySetInnerHTML={{
                    __html: section2.guidedTourContent.guidedTourContent
                  }}
                >
                </p>
                <p 
                  className="mt30"
                  dangerouslySetInnerHTML={{
                    __html: section2.sectionBodyOutro.sectionBodyOutro
                  }}
                >
                </p>
              </div>
              <div className="col-md-6 guided-tour-right">
                <img 
                  src={section2.guidedTourSectionImage.file.url} 
                  alt={section2.guidedTourSectionImage.description} 
                />
              </div>
            </div>

            <form
              className="default-form"
              action="https://gmail.us5.list-manage.com/subscribe/post?u=8888c103642d087afb1f42fef&amp;id=72e829b037&amp;SIGNUP=Tours%20Page"
              method="post"
              id="tour-form"
            >
              <div className="row mt-5 mb-4">
                <div className="col-lg-6 booking-left">
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        type="text"
                        placeholder={nameInputPlaceholder}
                        name="FNAME"
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        placeholder={companyInputPlaceholder}
                        name="COMPANY"
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        placeholder={contactInputPlaceholder}
                        name="PHONE"
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="email"
                        placeholder={emailInputPlaceholder}
                        name="EMAIL"
                      />
                    </div>
                    <div className="col-md-6">
                      <select name="BOOKING" required defaultValue={bookingTypeInputPlaceholder[0].textPlaceholder}>
                      {bookingTypeInputPlaceholder.map((option, i) => (
                         <option value={option.value} disabled={i == 0 ? true : false} key={i}>
                         {option.textPlaceholder}
                       </option>
                      ))}
                    </select>
                      <img src="/images/arrow.png" className="select-arrow" />
                    </div>
                    <div className="col-md-6">
                      <DatePicker
                        selected={tourDate}
                        onChange={date => this.setState({ tourDate: date })}
                        showPopperArrow={false}
                        placeholderText={datePickerInputPlaceholder}
                        name="SETDATE"
                      />
                      <img src="/images/arrow.png" className="select-arrow" />
                    </div>
                    <div className="col-md-6">
                      <DatePicker
                        selected={tourTime}
                        onChange={date => this.setState({ tourTime: date })}
                        showPopperArrow={false}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        placeholderText={timePickerInputPlaceholder}
                        name="SETTIME"
                      />
                      <img src="/images/arrow.png" className="select-arrow" />
                    </div>
                    <div className="col-md-6">
                      <select name="TICKET" required defaultValue={ticketTypeInputPlaceholder[0].textPlaceholder}>
                      {ticketTypeInputPlaceholder.map((option, i) => (
                         <option value={option.value} disabled={i == 0 ? true : false} key={i}>
                         {option.textPlaceholder}
                       </option>
                      ))}
                    </select>
                      <img src="/images/arrow.png" className="select-arrow" />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        placeholder={quantityInputPlaceholder}
                        name="QUANTITY"
                        value={quantity > 0 ? quantity : ''}
                        onChange={e => this.handleCounter(e)}
                        min={1}
                      />
                      <img
                        src="/images/plus.png"
                        className="incrementQuantity"
                        onClick={e => this.incrementQuantity(e)}
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-12">
                          <p class="mb0">{withGuidedTourInputPlaceholder}</p>
                        </div>
                        <div className="col-md-6">
                          <label className="checkbox-label">
                            <input type="radio" value="Yes" name="GUIDED" /> Yes
                          </label>
                        </div>
                        <div className="col-md-6">
                          <label className="checkbox-label">
                            <input type="radio" value="No" name="GUIDED" /> No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 booking-right">
                  <div className="row">
                    <div className="col-md-12">
                      <p>Tour Type</p>
                    </div>
                    {tourTypeInputPlaceholder.map((type, i) => (
                      <div className="col-md-6">
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          value={type.value}
                          name="TOURTYPE"
                          />
                        {type.textPlaceholder}
                      </label>
                      </div>
                    ))}
                    <div className="col-md-12 mt-2">
                      <textarea
                        name="MESSAGE"
                        rows="2"
                        placeholder={messageInputPlaceholder}
                      ></textarea>
                    </div>
                    <div className="col-md-12">
                      <div className="row align-items-center justify-content-between">
                        <div className="col-md-8 mt-2 mb-2">
                          <label className="checkbox-label">
                            <input type="checkbox" required /> {newsletterInputPlaceholder}
                          </label>
                        </div>
                        <div className="col-md-4 mt-2 mb-2">
                          <button className="btn w-100" type="submit">
                            {submitInputPlaceholder}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
        <Footer 
          footer={footer}
        />
      </Layout>
    );
  }
}

export default ToursPage;

export const pageQuery = graphql`
  query TourPageQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulToursPageGuidedTourSection {
      edges {
        node {
          admissionContentTitle
          guidedTourContentTitle
          sectionTitle
          sectionBodyIntro {
            sectionBodyIntro
          }
          admissionContent {
            admissionContent
          }
          guidedTourContent {
            guidedTourContent
          }
          sectionBodyOutro {
            sectionBodyOutro
          }
          guidedTourSectionImage {
            file {
              url
            }
            description
          }
        }
      }
    }
    allContentfulTourTourPage {
      nodes {
        pageTitle
        section1Tab
        section2Tab
        section3Tab
        section1Title
        section2 {
          sectionTitle
          sectionBodyIntro {
            sectionBodyIntro
          }
          admissionContent {
            admissionContent
          }
          guidedTourContent {
            guidedTourContent
          }
          sectionBodyOutro {
            sectionBodyOutro
          }
          guidedTourSectionImage {
            file {
              url
            }
            description
          }
        }
        nameInputPlaceholder
        companyInputPlaceholder
        contactInputPlaceholder
        emailInputPlaceholder
        bookingTypeInputPlaceholder {
          textPlaceholder
          value
        }
        datePickerInputPlaceholder
        timePickerInputPlaceholder
        ticketTypeInputPlaceholder {
          textPlaceholder
          value
        }
        quantityInputPlaceholder
        withGuidedTourInputPlaceholder
        tourTypeInputPlaceholder {
          textPlaceholder
          value
        }
        messageInputPlaceholder
        newsletterInputPlaceholder
        submitInputPlaceholder
      }
    }
    allContentfulNavigation(sort: { fields: createdAt }) {
      edges {
        node {
          id
          slug
          pageTitle
          submenu
        }
      }
    }
    allContentfulFooter {
      edges {
        node {
          facebookLink
          footerAddress
          footerEmail
          footerHeadline
          footerImage {
            file {
              url
            }
          }
          footerLandline
          footerMobileNumber
          footerOpeningDates
          footerOpeningHours
          instagramLink
          section
        }
      }
    }
  }
`;
